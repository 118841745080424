<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      :style="{
        backgroundImage: `url(${backgroundImage})`,
      }"
    >
      <!-- begin:: Content -->
      <div class="bg-white-o-50 rounded p-5 w-50">
        <h1 class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15" style="font-size: 150px">403</h1>
        <div class="font-size-h3 w-50 font-weight-light">
          Authentifizierung ist erforderlich. Bitte stelle sicher, dass du dich im SKR-VPN Netz befindest.
          Versuche dann zurück auf das Dashboard zu gelangen:
        </div>
        <router-link to="/reisetermine/overview">
          <b-button variant="primary" size="lg" class="btn btn-light-primary btn-bold w-20">
            Zur Reisetermin Übersicht...
          </b-button>
        </router-link>
        <!-- end:: Content -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error-1',
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + 'media/error/bg1.jpg';
    },
  },
};
</script>
